import * as React from "react"
import Layout from '../components/layout'
import styled from 'styled-components'

const Link = styled.a`
  margin: 30px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;

  :hover{
    text-decoration: underline;
  }
`

// markup
const Contact = () => {
  return (
    <Layout>
      <div style={{
        height: '381px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Link target="_blank" href="mailto:contact@heathcards.com"><strong>Email</strong><br/> contact@heathcards.com</Link>
        <Link target="_blank" href="https://www.instagram.com/HEATHCARDS">Instagram</Link>
        <Link target="_blank" href="https://www.youtube.com/channel/UCIZyflnfhJi_SPbydcpskUw">Youtube</Link>   
      </div> 
    </Layout>
  )
}

export default Contact
